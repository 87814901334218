<script lang="ts">
	import FontSpan from '../typography/FontSpan.svelte';
</script>

<div class="w-[280px] h-[140px] lg:w-[412px] lg:h-[186px] relative">
	<div
		class="hidden lg:block w-[100px] h-[100px] left-0 top-0 absolute bg-gray-100 rounded-[30px]"
	/>
	<div
		class="hidden lg:block w-[100px] h-[100px] left-[312px] top-[86px] absolute bg-gray-100 rounded-[30px]"
	/>

	<div
		class="flex flex-row p-[35px] w-[280px] lg:w-[396px] h-[130px] lg:h-[170px] left-0 top-0 absolute bg-gray-200
         rounded-[30px] border-4 border-gray-100 items-center gap-8"
	>
		<div class="flex">
			<slot name="image">
				<img
					class="w-[84px] h-[67px]"
					src="/images/game-feature-char-1.png"
					alt="game feature icon 1"
				/>
			</slot>
		</div>
		<slot name="text">
			<div class="lg:hidden">
				<FontSpan varient="body-R-03" class="text-gray-50">
					Diverse and Unique<br />Playable Tanks
				</FontSpan>
			</div>
			<div class="hidden lg:block">
				<FontSpan varient="body-R-05" class="text-gray-50">
					Diverse and Unique<br />Playable Tanks
				</FontSpan>
			</div>
		</slot>
	</div>
</div>
