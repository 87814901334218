<script lang="ts">
	import { browser } from '$app/environment';
	import { goto } from '$app/navigation';
	import GameFeature from '$lib/components/cards/GameFeature.svelte';
	import MarketTankCard from '$lib/components/cards/MarketTankCard.svelte';
	import InfiniteHorizontalScroll from '$lib/components/loading/InfiniteHorizontalScroll.svelte';
	import Overlay from '$lib/components/loading/Overlay.svelte';
	import FontSpan from '$lib/components/typography/FontSpan.svelte';
	import { OrderService, imageHTTPURL, initOrderSearchRequest } from '$lib/service/OrderService';
	import type { MarketOrderDetails } from '$lib/types';
	import type { PageServerData } from './$types';

	export let data: PageServerData;
	const { marketOrderDetails } = data;

	let processing = false;

	let marketOrders1 = marketOrderDetails;
	let newMarketOrdersPage1 = [] as MarketOrderDetails[];
	let pageNumber = 1;
	let hasMore1 = true;
	async function refreshActiveMarket() {
		const service = new OrderService();
		try {
			let marketItemsPage = await service.getOrders(
				initOrderSearchRequest(),
				'-created_at',
				pageNumber
			);
			hasMore1 = Object.keys(marketItemsPage.links).findIndex((k) => k === 'next') > -1;
			newMarketOrdersPage1 = imageHTTPURL(marketItemsPage.data);
			return true;
		} catch (error) {
			console.log('failed to load home page recent NFTs ', error);
			newMarketOrdersPage1 = [];
			return false;
		}
	}
	$: marketOrders1 = [...marketOrders1, ...newMarketOrdersPage1];

	let paused = true;
	function handleVideoOnClick() {
		paused = !paused;
	}
</script>

<svelte:head>
	<title>Home</title>
</svelte:head>

<Overlay show={processing} />

<div class="dark:text-white flex w-full m-auto">
	<div
		class="w-14 h-14 z-50 discord-button bg-gray-300 rounded-[100px] shadow border-4 border-gray-350 justify-center items-center inline-flex"
	>
		<div class="w-8 h-8 relative">
			<a href="https://discord.gg/JU8QhRePvP" target="_blank" rel="noreferrer">
				<img src="images/discord-icon.png" alt="discord" />
			</a>
		</div>
	</div>
	<div class="flex flex-col w-full justify-items-start">
		<!-- CTA section -->
		<div class="flex flex-col img-bg w-full min-h-[500px] lg:h-[900px]">
			<div
				class="flex flex-col bg-black opacity-70 w-full min-h-[500px] lg:h-[900px] justify-center lg:justify-items-start lg:items-end"
			>
				<div class="flex flex-col w-full max-w-[1284px] m-auto px-4 lg:px-0">
					<div class="flex flex-grow justify-center">
						<div class="flex flex-col items-center text-center lg:text-left">
							<FontSpan varient="Title-01" class="text-white text-3xl lg:text-5xl"
								><h1>Fortress Arena</h1></FontSpan
							>
							<FontSpan varient="body-R-01" class="text-white text-base lg:text-lg mt-2">
								Fortress Arena has been released. Enjoy a great battle.
							</FontSpan>
							<!-- <div
								role="button"
								tabindex="0"
								style="cursor: pointer;"
								on:click={() => {
									ga.addEvent('WEB_CLICK_CTA', 'CLICK');
									window.open(
										'https://play.google.com/store/apps/details?id=com.Atomrigs.FortressArena&hl=en-PH&pli=1',
										'_blank'
									);
								}}
								on:keydown={(e) => {
									if (e.key === 'Enter') {
										ga.addEvent('WEB_CLICK_CTA', 'CLICK');
										window.open(
											'https://play.google.com/store/apps/details?id=com.Atomrigs.FortressArena&hl=en-PH&pli=1',
											'_blank'
										);
									}
								}}
								class="download-button text-secondary-600 flex mt-6 lg:mt-10 w-[80%] lg:w-[300px] h-[40px] lg:h-[50px] justify-center items-center text-base lg:text-xl"
							> -->
							<div
								role="button"
								tabindex="0"
								class="download-button text-secondary-600 flex mt-6 lg:mt-10 w-[80%] lg:w-[300px] h-[40px] lg:h-[50px] justify-center items-center text-base lg:text-xl"
							>
								<img src="images/google-play-icon.png" class="mr-2" alt="googleplay" />
								<FontSpan varient="body-EB-015" class="text-secondary-600">Download</FontSpan>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Card section -->
		<div class="flex flex-col max-w-[1284px] m-auto">
			<div class="flex flex-col xl:flex-row gap-x-6 pb-28 pt-28">
				<a href="/marketplace">
					<div class="w-[320px] h-[175px] lg:w-[630px] lg:h-[344px] relative">
						<div class="w-[320px] h-[175px] lg:w-[630px] lg:h-[344px] left-0 top-0 absolute">
							<div
								class="w-[320px] h-[140px] lg:w-[630px] lg:h-[300px] left-0 top-[34px] absolute bg-gradient-to-r from-blue-700 to-purple-600 rounded-[30px] border-l-2 border-r-2 border-t-2 border-b-2 border-white border-opacity-10"
							/>
							<div
								class="w-[200px] h-[187px] left-[143px] lg:w-[368px] lg:h-[344px] lg:left-[262px] top-0 absolute justify-center items-center inline-flex"
							>
								<div class="w-[200px] h-[187px] lg:w-[368px] lg:h-[344px] relative">
									<img
										class="w-[196px] h-[176px] left-[5px] top-[11px] lg:w-[359px] lg:h-[323px] lg:left-[9px] lg:top-[21px] absolute"
										src="/images/sub-banner-char-1.png"
										alt="sub banner character 1"
									/>
								</div>
							</div>
						</div>
						<div
							class="w-[70px] h-[81px] left-[22px] top-[40px] lg:w-[129px] lg:h-[149px] lg:left-[40px] lg:top-[74px] absolute flex-col justify-start items-start gap-3 inline-flex"
						>
							<!-- FontSpan for Mobile -->
							<div class="flex flex-col justify-start items-start lg:hidden">
								<FontSpan varient="body-B-05" class="text-white">Market</FontSpan>
								<FontSpan varient="body-B-05" class="text-white">Place</FontSpan>
							</div>

							<!-- FontSpan for Desktop -->
							<div class="flex flex-col justify-start items-start hidden lg:block">
								<FontSpan varient="body-B-01" class="text-white">Market</FontSpan>
								<FontSpan varient="body-B-01" class="text-white">Place</FontSpan>
							</div>

							<!-- FontSpan for Mobile -->
							<div class=" lg:hidden">
								<FontSpan varient="body-R-07" class="text-white">마켓플레이스</FontSpan>
							</div>

							<!-- FontSpan for Desktop -->
							<div class="hidden lg:block">
								<FontSpan varient="body-R-03" class="text-white">마켓플레이스</FontSpan>
							</div>
						</div>
						<div
							class="w-5 h-5 lg:w-9 lg:h-9 left-[22px] top-[141px] lg:left-[40px] lg:top-[258px] absolute"
						>
							<div
								class="w-5 h-5 lg:w-9 lg:h-9 left-0 top-0 absolute bg-white bg-opacity-30 rounded-full shadow"
							/>
							<div class="w-2 h-2 lg:w-6 lg:h-6 left-[3px] top-[3px] left-[6px] top-[6px] absolute">
								<img src="/images/chevron-right-icon-1.png" alt="chevron right" />
							</div>
						</div>
					</div>
				</a>
				<a
					href="https://atomrigslab.gitbook.io/fortress-arena/game-play/game-content/league/league-season-rewards/guide-for-lucky-ball"
				>
					<div class="w-[320px] h-[175px] lg:w-[630px] lg:h-[344px] relative">
						<div class="w-[320px] h-[175px] lg:w-[630px] lg:h-[344px] left-0 top-0 absolute">
							<div
								class="w-[320px] h-[140px] lg:w-[630px] lg:h-[300px] left-0 top-[34px] absolute bg-gradient-to-r from-yellow-600 to-fuchsia-500 rounded-[30px] border-l-2 border-r-2 border-t-2 border-b-2 border-white border-opacity-10"
							/>
							<div
								class="w-[200px] h-[187px] left-[143px] lg:w-[368px] lg:h-[344px] lg:left-[262px] top-0 absolute justify-center items-center inline-flex"
							>
								<div class="w-[200px] h-[187px] lg:w-[368px] lg:h-[344px] relative">
									<img
										class="w-[196px] h-[176px] left-[5px] top-[11px] lg:w-[359px] lg:h-[323px] lg:left-[9px] lg:top-[21px] absolute"
										src="/images/sub-banner-char-2.png"
										alt="sub banner character 2"
									/>
								</div>
							</div>
						</div>
						<div
							class="w-[70px] h-[81px] left-[22px] top-[40px] lg:w-[129px] lg:h-[149px] lg:left-[40px] lg:top-[74px] absolute flex-col justify-start items-start gap-3 inline-flex"
						>
							<!-- FontSpan for Mobile -->
							<div class="flex flex-col justify-start items-start lg:hidden">
								<FontSpan varient="body-B-05" class="text-white">League</FontSpan>
								<FontSpan varient="body-B-05" class="text-white">Season</FontSpan>
							</div>

							<!-- FontSpan for Desktop -->
							<div class="flex flex-col justify-start items-start hidden lg:block">
								<FontSpan varient="body-B-01" class="text-white">League</FontSpan>
								<FontSpan varient="body-B-01" class="text-white">Season</FontSpan>
							</div>

							<!-- FontSpan for Mobile -->
							<div class=" lg:hidden">
								<FontSpan varient="body-R-07" class="text-white">리그 시즌제</FontSpan>
							</div>

							<!-- FontSpan for Desktop -->
							<div class="hidden lg:block">
								<FontSpan varient="body-R-03" class="text-white">리그 시즌제</FontSpan>
							</div>
						</div>
						<div
							class="w-5 h-5 lg:w-9 lg:h-9 left-[22px] top-[141px] lg:left-[40px] lg:top-[258px] absolute"
						>
							<div
								class="w-5 h-5 lg:w-9 lg:h-9 left-0 top-0 absolute bg-white bg-opacity-30 rounded-full shadow"
							/>
							<div class="w-2 h-2 lg:w-6 lg:h-6 left-[3px] top-[3px] left-[6px] top-[6px] absolute">
								<img src="/images/chevron-right-icon-1.png" alt="chevron right" />
							</div>
						</div>
					</div>
				</a>
			</div>
		</div>

		<!-- Introduction section -->
		<div class="flex flex-col m-auto px-2 pl-5 max-w-[1284px]">
			<div class="w-full max-w-[100px] h-[25px] justify-start items-start gap-2.5">
				<FontSpan varient="body-B-06" class="text-gray-70">Introduction</FontSpan>
			</div>
			<div
				class="w-full max-w-xs max-w-[600px] xl:max-w-[1284px] py:8 flex-col justify-start items-start lg:pt-2 sm:pt-10"
			>
				<FontSpan varient="Title-03" class="text-primary-500">The Next Web3 Game</FontSpan>
				<FontSpan
					varient="body-R-02"
					class="w-full max-w-xs max-w-[600px] xl:max-w-[1284px] text-white"
				>
					Fortress Arena is a turn-based artillery game where you battle enemies
				</FontSpan>
				<FontSpan
					varient="body-R-02"
					class="w-full max-w-xs max-w-[600px] xl:max-w-[1284px] text-white"
				>
					with Fortress blockchain tokens on the Fortress Planet
				</FontSpan>
			</div>
			<FontSpan varient="Title-04" class="text-gray-50 py-10 lg:pt-2">Game Features</FontSpan>
			<div
				class="flex flex-col items-center xl:flex-row pt-2 sm:pt-7 gap-y-3 sm:gap-y-6 xl:gap-x-6"
			>
				<GameFeature>
					<img
						slot="image"
						class="w-[45px] h-[36px] lg:w-[84px] lg:h-[67px]"
						src="/images/game-feature-char-1.png"
						alt="game feature icon 1"
					/>
					<FontSpan slot="text" varient="body-R-03" class="text-gray-50">
						Diverse and Unique<br />Playable Tanks
					</FontSpan>
				</GameFeature>
				<GameFeature>
					<img
						slot="image"
						class="w-[45px] h-[36px] lg:w-[84px] lg:h-[67px]"
						src="/images/game-feature-char-2.png"
						alt="game feature icon 2"
					/>
					<FontSpan slot="text" varient="body-R-03" class="text-gray-50">
						PvE and PvP Fun Modes
					</FontSpan>
				</GameFeature>
				<GameFeature>
					<img
						slot="image"
						class="w-[45px] h-[36px] lg:w-[84px] lg:h-[67px]"
						src="/images/game-feature-char-3.png"
						alt="game feature icon 3"
					/>
					<FontSpan slot="text" varient="body-R-03" class="text-gray-50">
						Turn-based Play<br />with Strategic Shootings
					</FontSpan>
				</GameFeature>
			</div>

			<!-- Video Section -->
			<div class="pt-2 sm:pt-20 xl:flex xl:justify-center">
				<video
					class="w-full max-w-xs sm:max-w-[600px] xl:max-w-[1100px]"
					poster="/images/home-arena-video-preview.png"
					preload="none"
					src="/videos/home-arena-video.mp4"
					on:click={handleVideoOnClick}
					on:keypress={handleVideoOnClick}
					bind:paused
				>
					<track kind="captions" />
				</video>
			</div>
		</div>

		<!-- Funplay section -->
		<div
			class="flex flex-col lg:flex-row pt-20 lg:pt-80 max-w-[1284px] px-4 lg:px-0 mx-4 lg:mx-12 xl:m-auto"
		>
			<div
				class="flex flex-col lg:flex-row w-full lg:w-[1284px] justify-start items-center gap-10 lg:gap-20"
			>
				<div class="w-full max-w-[521px] h-auto lg:h-[533px] relative mx-auto">
					<div class="w-full h-full absolute bg-gradient-to-t from-gray-200 to-gray-200">
						<img
							class="w-full h-full absolute"
							src="/images/fun-play-image-1.png"
							alt="rental list"
						/>
					</div>
				</div>
				<div
					class="w-full max-w-[630px] pt-5 pb-10 lg:pb-10 grow shrink basis-0 flex-col justify-start items-start gap-5 lg:gap-10 mx-auto"
				>
					<div class="justify-start items-start inline-flex">
						<FontSpan varient="body-B-06" class="text-gray-70">Fun Play</FontSpan>
					</div>
					<div class="flex-col justify-start items-start gap-6 flex">
						<FontSpan varient="Title-02" class="text-white">Play freely with rental tanks</FontSpan>
						<FontSpan
							varient="body-R-03"
							class="w-full text-gray-50 text-[18px] font-normal leading-relaxed"
						>
							Players are encouraged to own NFTs in order to fully enjoy the game content. But, for
							players who don't own tank NFTs, there is playable NFT rental process within the game.<br
							/><br />
							A user can play the game by borrowing tank NFTs from the in-game rental, and the profit
							from winning is divided with tank NFT owners. The owners can rent their tank NFTs through
							the smart contract in the blockchain.
						</FontSpan>
					</div>
				</div>
			</div>
		</div>

		<!-- Tank NFT section -->
		<div class="flex flex-col tank-nft-bg pt-20 px-4 lg:px-0 lg:pt-52 pb-20 lg:pb-52">
			<div class="flex flex-col max-w-[1284px] mx-4 lg:mx-auto">
				<div class="flex flex-col justify-center items-center gap-5 lg:gap-10 mb-10 lg:mb-14">
					<div class="text-center">
						<FontSpan varient="body-B-06" class="text-gray-50">Tank NFT</FontSpan>
					</div>
					<div class="flex flex-col justify-center items-center gap-4 lg:gap-6">
						<FontSpan varient="Title-02" class="lg:text-center text-white">
							Fortress Tank NFT is used as a tank character to<br class="hidden lg:inline" /> defeat
							enemy tanks in the game.
						</FontSpan>
						<FontSpan varient="body-R-03" class="w-full lg:w-[780px] lg:text-center text-gray-50">
							Each tank also has its own characteristics such as the race, class, color, element, or
							material, and these attributes affect the stats (e.g. damage) in tank battles in the
							game.
						</FontSpan>
					</div>
				</div>
				<div class="flex flex-wrap justify-center items-center gap-4 lg:gap-6">
					<img
						class="nft-image w-[210px] lg:w-[237px]"
						src="/images/tank-nft-1.png"
						alt="tank nft 1"
					/>
					<img
						class="nft-image w-[210px] lg:w-[237px]"
						src="/images/tank-nft-2.png"
						alt="tank nft 2"
					/>
					<img
						class="nft-image w-[210px] lg:w-[237px]"
						src="/images/tank-nft-3.png"
						alt="tank nft 3"
					/>
					<img
						class="nft-image w-[210px] lg:w-[237px]"
						src="/images/tank-nft-4.png"
						alt="tank nft 4"
					/>
					<img
						class="nft-image w-[210px] lg:w-[237px]"
						src="/images/tank-nft-5.png"
						alt="tank nft 5"
					/>
				</div>
			</div>
		</div>

		<!-- Luckyball section -->
		<div class="flex flex-col bg-black pt-10 pb-10">
			<div
				class="flex flex-col justify-center items-center max-w-[1200px] mx-auto text-center text-white"
			>
				<FontSpan varient="body-B-06" class="text-gray-70">Lucky Ball Rewards</FontSpan>
				<div class="flex-col justify-start items-start gap-6 flex mt-10 lg:mt-14 mb-10 lg:mb-14">
					<FontSpan varient="Title-02" class="text-white"
						>Win battles, get Lucky Balls, and enjoy these special prizes!</FontSpan
					>
					<FontSpan
						varient="body-R-03"
						class="w-full text-gray-50 text-[18px] font-normal leading-relaxed"
					>
						In Fortress Arena, Lucky Balls are coveted orbs, each concealing a unique 6-digit code. <br
						/>
						Earned through victorious League mode battles, these magical spheres grant access to exclusive
						rewards.<br /><br />
						Your skill in battle determines the number of Lucky Balls earned, <br />
						influenced by factors like tank ownership and class. These orbs are kept safe in a smart
						contract on the blockchain. <br /><br />
						They unlock cool prizes like rare mutant NFTs and Crings.<br />
						Just compare the codes, count your Lucky Balls, and you can get amazing rewards in Fortress
						Arena. <br />
						<br />
					</FontSpan>
				</div>
				<a
					href="https://atomrigslab.gitbook.io/fortress-arena/game-play/game-content/league/league-season-rewards"
					target="_blank"
					class="flex items-center justify-center w-56 h-14 mb-10 p-2 bg-black border border-white rounded-full text-white hover:bg-gray-700 button-shadow"
				>
					<img class="w-7 h-7 mr-2" src="/images/icon-gitbook-no-circle.png" alt="gitbook" />
					<FontSpan varient="body-EB-02" class="lg:text-center text-white">Luckyball Guide</FontSpan
					>
				</a>
			</div>
			<div class="flex justify-center">
				<img
					class="w-[450px] max-w-full"
					src="/images/bg_luckyball_image.png"
					alt="luckyball"
					loading="lazy"
				/>
			</div>
		</div>

		<!-- Listed section -->
		<div
			class="flex flex-col max-w-[1284px] w-full pt-10 lg:pt-52 pb-6 px-4 lg:px-0 lg:pb-24 lg:items-center lg:mx-auto relative"
		>
			<div class="flex flex-col lg:flex-row lg:items-center mb-8 lg:mb-16">
				<FontSpan varient="Title-02" class="text-white pb-5 lg:pb-0 text-sm lg:text-base"
					>Recently Listed</FontSpan
				>
				<!-- svelte-ignore a11y-click-events-have-key-events -->
				<!-- svelte-ignore a11y-no-static-element-interactions -->
				<div
					style="cursor: pointer;"
					class="download-button pr-3.5 relative lg:absolute right-0 text-secondary-600 flex w-[250px] h-[40px] lg:w-[300px] justify-center items-center text-xs lg:text-sm"
					on:click={() => goto(`/marketplace`)}
				>
					<div class="lg:hidden">
						<FontSpan varient="body-EB-02">Go to Marketplace</FontSpan>
					</div>
					<div class="hidden lg:block">
						<FontSpan varient="body-EB-015">Go to Marketplace</FontSpan>
					</div>
					<img src="images/chevron-right-icon-2.png" class="ml-1" alt="chevron icon" />
				</div>
			</div>
			<div
				class="flex flex-col lg:flex-row w-full overflow-x-hidden lg:overflow-x-auto justify-start items-center gap-2 lg:gap-4 overflow-auto pb-2"
			>
				{#if browser}
					{#each marketOrders1 as marketOrder}
						<MarketTankCard item={marketOrder} />
					{/each}
					<InfiniteHorizontalScroll
						hasMore={hasMore1}
						on:loadMore={async () => {
							pageNumber += 1;
							await refreshActiveMarket();
						}}
					/>
				{/if}
			</div>
		</div>

		<!-- Mutant Tank section -->
		<div class="flex flex-col pt-80 pb-72 px-4 lg:px-0 mutant-bg">
			<div class="flex flex-col items-start max-w-[1284px] w-full px-5 xl:px-0 m-auto">
				<div class="flex-col justify-start items-start gap-10 inline-flex w-full">
					<div class="justify-start items-start gap-2.5 inline-flex w-full">
						<FontSpan
							varient="body-B-06"
							class="text-gray-70 text-[18px] xl:text-[20px] font-bold leading-relaxed"
							>Mutant tanks</FontSpan
						>
					</div>
					<div class="flex-col justify-start items-start gap-6 flex w-full">
						<FontSpan varient="Title-02" class="text-white text-glow"
							>Want more?<br />There are Mutant tanks</FontSpan
						>
						<FontSpan varient="body-R-03" class="text-gray-50"
							>Mutant tanks are very rare because they are the type of tank that did not exist
							before,<br />and new mutant tanks are continuously added over time.</FontSpan
						>
					</div>
				</div>
			</div>
		</div>

		<!-- Development section -->
		<div class="bg py-28 my-28">
			<div class="flex flex-col justify-center items-center text-center">
				<FontSpan varient="body-B-06" class="text-gray-70">Development</FontSpan>
				<div class="flex flex-row items-stretch max-w-[1200px] mt-10 text-black">
					<a href="https://www.atomrigs.io/" target="blank" class="flex-1">
						<div class="h-[50px] lg:h-[92px] mb-7">
							<img src="/images/logo-AtomrigsLab.png" alt="" class="h-full w-auto" />
						</div>
						<FontSpan
							varient="body-R-03"
							class="w-full text-gray-50 text-[18px] font-normal leading-relaxed"
						>
							Atomrigs Lab is a blockchain tech company<br />
							with many years of experience<br />
							in developing blockchain based services
						</FontSpan>
					</a>
				</div>
			</div>
		</div>

		<!-- ContactUs section -->
		<div class="bg-black py-52">
			<div class="flex flex-col justify-center items-center text-center">
				<FontSpan varient="Title-02" class="text-white">Contact Us</FontSpan>
				<div class="mt-10 flex flex-col items-center text-white">
					<FontSpan
						varient="body-R-03"
						class="w-full text-gray-50 text-[18px] font-normal leading-relaxed"
					>
						If you have any questions, concerns, or feedback, <br />
						please feel free to reach out to our customer support team <br />
						at Fortress Arena. We are here to assist you.
					</FontSpan>
					<a
						href="mailto:fortress_cs@atomrigs.io"
						class="flex items-center justify-center w-56 h-14 mt-6 p-2 bg-black border border-white rounded-full text-white hover:bg-gray-700 shadow-[2px_2px]"
					>
						<img class="w-6 h-6 mr-2" src="/images/icon-mail.png" alt="mail-icon" />
						<FontSpan varient="body-EB-02" class="text-center text-white">Customer Support</FontSpan
						>
					</a>
				</div>
			</div>
		</div>
	</div>
</div>

<style>
	.download-button {
		border-radius: 6px;
		border: 2px solid var(--p-60, #49b4b1);
		box-shadow: 0px 6px 0px 0px #378785;
		padding: 0px 32px 0px 24px;
	}

	.discord-button {
		right: 40px;
		bottom: 40px;
		position: fixed;
	}

	.img-bg {
		background-color: #cccccc;
		background-image: url('/images/dashboard-bg-1.png');
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.tank-nft-bg {
		background-color: #cccccc;
		background-image: url('/images/dashboard-bg-2.png');
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.mutant-bg {
		background-color: #cccccc;
		background-image: url('/images/dashboard-bg-3.jpg');
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.nft-image {
		mix-blend-mode: screen;
	}

	:global(.text-glow) {
		text-shadow: 0px 0px 14px #49b4b1;
	}

	.button-shadow {
		box-shadow: 2px 2px;
	}
</style>
